import { getQueryString, isObject } from '@shein/common-function'
import searchWordsGlobalAnalysis from 'public/src/pages/common/biz_helper/gb_sw_analysis'

const { IS_RW = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}


// 埋点 goods_to_list 拼接
/**
 * @param {Object} productData 商品数据
 * @param {number} index 坑位
 * @param {number} compIndex 运营位置
 * @returns
 */
export const getGoodsToList = (productData, index, compIndex) => {
  // goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`pri_在售价格｜pri_原价`其它标识`销售或促销属性`mall_mallcode`算法额外标识
  if (!productData) return
  const {
    goods_id,
    goods_sn,
    productRelationID,
    mall_code,
    salePrice = {},
    local_goods_id,
    retailPrice = {}
  } = productData
  const goodsToList = [
    goods_id,
    goods_sn, // skc
    productRelationID, // spu_id
    index, // 坑位
    1, // 页码
    compIndex, // 运营位置
    '-', // 流量标识
    '-', // 置顶标识
    `pri_${salePrice?.usdAmount || 0}|pri_${retailPrice?.usdAmount || 0}`, // 价格
    '-', // 其他标识
    '-', // 销售或促销属性
    `mall_${mall_code || '-'}` // mall类型_mallid
  ]
  local_goods_id && goodsToList.push(`ypdg_goods_id_${local_goods_id}`)
  return goodsToList.join('`')
}

export const ProductListAnalysis = {
  /**
   * 初始化页面埋点信息，赋值window变量，因为别的地方也有在用
   * @param {*} param0
   */
  init({
    catInfo,
    fhContext,
    googleContext,
    currentCat,
    searchKeywords,
    sum,
    goodsCrowId,
    traceId = 0,
    pageParamExtend = {},
    searchRequestStatus,
    request_ext = {},
  }) {
    const {
      type,
      attr_ids,
      sort = 0,
      ici = 0,
      tag_ids = 0,
      min_price,
      max_price,
      channel_id,
      tsp_ids,
      picks_id,
      picksType
    } = catInfo
    const pageId = picksType || (IS_RW ? 9 : 1)

    /**
     * sa
     * ===============================================================
     */
    let page_id = '',
        page_name = '',
        page_param = {},
        aod_id = '',
        cat_id = ''
    const pageNames = [
      'page_real_class',
      'page_select_class',
      'page_search',
      'page_goods_group'
    ]
    if (ici) {
      const r = ici.split('_').find(i => i.indexOf('aod') > -1) || ''
      aod_id = r.split('=').length && r.split('=')[1] || ''
    }

    /**
     * sa
     * ===============================================================
     */
    switch (type) {
      case 'entity':
        {
          // sa
          page_id = 3
          page_name = 'page_real_class'
          page_param = {
            sort: sort || '',
            result_count: sum,
            aod_id,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
            entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          }
          cat_id = catInfo.entity_id
        }
        break
      case 'selection':
        {
          // sa
          page_id = 46
          page_name = 'page_select_class'
          page_param = {
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
            product_select_id: getQueryString({ key: 'product_select_id' }) || '',
            entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          }
          cat_id = catInfo.select_id
        }
        break
      case 'daily_new':
        {
          // sa
          const now = window.location.href.match(/[\d]{4}-[\d]{1,2}-[\d]{1,2}/)
          page_id = 12
          page_name = 'page_goods_group'
          page_param = {
            group_content: 'daily_new',
            date: (now && now[0]) || '',
            aod_id,
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
          }
        }
        break
      case 'search':
        {
          const { is_suggest_res, suggest_words, origin_words, suggest_total } = searchKeywords
          const { is_from_list_feeds } = catInfo

          const { result_type, word_type, entrancetype, result_content } = searchWordsGlobalAnalysis.get()

          const query_infor = Object.assign({}, request_ext || {})
          const queryInforString = Object.entries(query_infor)
            .map(([key, value]) => `${key}_${value ?? ''}`)
            .join('|')

          // sa
          page_id = 16
          page_name = 'page_search'
          page_param = {
            word_from: word_type,
            is_from_list_feeds: String(is_from_list_feeds || 2),
            aod_id,
            channel_id,
            result_type,
            search_content: origin_words || suggest_words,
            result_count: is_suggest_res ? 0 : Number(sum) || 0,
            rec_word: suggest_words,
            rec_count: suggest_total,
            search_request_status: searchRequestStatus,
            query_infor: queryInforString,
            // 搜索结果页无法直接从url拿到，所以
            entrancetype: entrancetype || '-',
            result_content: result_content || window?.argResultContent || '-',
            rec_result_count: '-',
            ...pageParamExtend
          }
          if (!IS_RW) {
            let crowId = ''
            for (var key in goodsCrowId) {
              crowId += ',' + key + '_' + goodsCrowId[key]
            }
            page_param.crowd_id = crowId.substring(1)
          }
        }
        break
      case 'picks':
        {
          // sa
          page_id = 21
          page_name = 'page_goods_group'
          page_param = {
            group_content: type.replace('-', '_'),
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
            entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          }
        }
        break
      case 'selling':
        {
          // sa
          page_id = 21
          page_name = 'page_goods_group'
          page_param = {
            group_content: 'selling_point',
            result_count: sum,
            page_list_type: catInfo.pageListType,
            styleType: catInfo.styleType,
          }
        }
        break
    }

    /**
     * sa 是否是shein pick页面判断赋值差异
     * ===============================================================
     */
    if (catInfo.type == 'picks') {
      // sa
      page_id = 21
      page_name = 'page_goods_group'
      page_param = {
        group_content: IS_RW ? 'promo_discount' : 'shein_picks',
        child_contentid: pageId || '0',
        result_count: sum,
        styleType: catInfo.styleType,
        ...page_param // 别问，问我也是不知道。。。。
      }
    }

    /**
     * 特定参数修改
     * ===============================================
     */
    const fallPageType = ['entity', 'selection', 'daily_new', 'search', 'picks', 'selling']
    if (fallPageType.includes(type)) {
      // sa
      page_param = {
        ...page_param,
        src_module: catInfo.url_from ? 'ads' : catInfo.src_module || '',
        src_tab_page_id: catInfo.src_tab_page_id || ''
      }

      // 因为有些src_identifier转了有些没转
      // 导致传入src_identifier的字符串没转但带有%符号就会报错
      // 通过捕获错误去修正值
      let src_identifier = ''
      try {
        src_identifier = catInfo.url_from ? `uf=${catInfo.url_from}` : decodeURIComponent(catInfo.src_identifier || '')
      } catch (el) {
        src_identifier = catInfo.url_from ? `uf=${catInfo.url_from}` : (catInfo.src_identifier ?? '')
      }

      page_param.src_identifier = src_identifier
    }
    page_param.pagefrom = ici || ''               // pagefrom
    page_param.tag_id = tag_ids                   // 标签云
    page_param.price_range = `${min_price || '-'}\`${max_price || '-'}`       // 价格

    if (pageNames.includes(page_name)) {
      let attribute = []
      const { child_cat_id, child_id, brand_ids } = catInfo
      attr_ids && (attribute = attribute.concat((attr_ids?.split('-') ?? [])))
      brand_ids && (attribute = attribute.concat((brand_ids?.split(',') ?? []).map(id => `brand_${id}`)))
      attribute = attribute.length ? attribute.join('-') : '0'

      page_param = Object.assign({}, page_param, {
        category_id: ( catInfo.type == 'picks' ? picks_id : cat_id ) || '',
        source_category_id: currentCat.parent_id || '',
        child_id: child_cat_id || child_id || '0',
        pagefrom: ici || '-',
        sort,
        attribute,
        tsps: tsp_ids || '0',
        aod_id,
        sourceId: fhContext.rid,
        attributionToken: googleContext.attributionToken,
      })
    }
    if (type == 'search') {
      const { pagefrom } = searchWordsGlobalAnalysis.get()
      page_param['pagefrom'] = pagefrom || ici?.replace?.('`_fb`', searchKeywords.is_suggest_res ? '`fb1`' : '`fb0`')
      page_param['category_id'] = ''
      page_param['source_category_id'] = ''
    }
    page_param['user_path'] = catInfo.userpath || '-'
    /**
     * 以上那一堆逻辑，就是为了给以下变量赋值
     * ===============================================
     * ===============================================
     * ===============================================
     * ===============================================
     */
    // 1. sa
    window.SaPageInfo = {
      start_time: new Date().getTime(),
      page_id,
      page_name,
      page_param
    }
    if (traceId != 0 && traceId != 1 && typeof gbExposeTraceid !== 'undefined') {
      // console.log('### trace_id:' + traceId)
      gbExposeTraceid('setComponent', {
        componentName: 'goodslist',
        traceid: traceId
      })
    }
  },
  updatePageParam(updateValue) {
    if (!isObject(updateValue)) return console.warn('updateValue is not Object')

    SaPageInfo.page_param = {
      ...SaPageInfo.page_param,
      ...updateValue
    }

    sa('set', 'setPageData', SaPageInfo)
  },
  /**
   * 发送pv
   */
  sendPageView() {
    //进入页面
    // 1. sa
    if (typeof sa !== 'undefined') {
      sa('set', 'setPageData', window.SaPageInfo)
      sa('send', 'pageEnter', { start_time: new Date().getTime() })

      // 退出页面
      window.onbeforeunload = function () {
        sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }
    }
  },
}
